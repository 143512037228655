@tailwind components;
@tailwind utilities;

.block-picker {
  box-shadow: none !important;
}
.block-picker input {
  height: 30px !important;
  padding: 10px !important;
}

@layer utilities {
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .transition {
    transition: 250ms all;
  }
}
